/**
 * AAA IDP Form - Summary: Licence details component
 * @flow
 */

import * as React from 'react';
import Text from '../text/Text';
import { connect } from 'react-redux';
import { Fn } from '../../../functions/Functions';
import type { Addresses, AutoClub, Field, Names } from '../../../types/Types';
import '../Summary.css';
import { DELIVERY_METHOD_POSTAGE } from '../../../data/Data';

type Props = {
  addresses: Addresses,
  club: AutoClub,
  deliveryMethod: Field,
  images: {
    licenceBack: Field,
    licenceFront: Field
  },
  licenceClass: Field,
  licenceDetailsRef: { current: { offsetTop: {} } },
  licenceExpiry: Field,
  licenceNumber: Field,
  names: Names,
  stateOfIssue: Field,
  wearGlasses: Field
};

export class LicenceDetails extends React.Component<Props> {
  static defaultProps = {
    addresses: {
      licenceAddress: {
        value: ''
      },
      licenceDeliveryAddress: {
        value: ''
      }
    },
    club: {
      autoClub: {
        error: false,
        value: ''
      }
    },
    deliveryMethod: {
      error: false,
      value: '',
    },
    images: {
      licenceBack: {
        value: ''
      },
      licenceFront: {
        value: ''
      }
    },
    licenceClass: {
      error: false,
      value: ''
    },
    licenceDetailsRef: { current: { offsetTop: {} } },
    licenceExpiry: {
      error: false,
      value: ''
    },
    licenceNumber: {
      error: false,
      value: ''
    },
    names: {
      licenceAddressFullName: {
        error: false,
        value: ''
      }
    },
    stateOfIssue: {
      error: false,
      value: ''
    },
    wearGlasses: {
      error: false,
      value: ''
    }
  };

  render() {
    const {
      addresses,
      deliveryMethod,
      images,
      licenceClass,
      licenceExpiry,
      licenceNumber,
      names,
      stateOfIssue,
      wearGlasses
    } = this.props;

    return (
      <div id="licence-details">
        <div className="details">
          <div className="text-list">
            <Text label="Licence number" name="licenceNumber" value={licenceNumber.value} />
            <Text label="Licence class" name="licenceClass" value={licenceClass.value} />
            <Text label="Do you wear glasses while driving?" name="wearGlasses" value={wearGlasses.value} />
            <Text label="Licence expiry date" name="licenceExpiry" value={Fn.invertDate(licenceExpiry.value)} />
            <Text label="State of issue" name="licenceStateOfIssue" value={stateOfIssue.value} />
            <Text label="Address on licence" name="licenceAddress" value={addresses.licenceAddress?.value} />
            {deliveryMethod?.value === DELIVERY_METHOD_POSTAGE.value && <div>
              {names.licenceDeliveryAddressFullName?.value ? (
                <Text
                  label="Delivery name"
                  name="licenceDeliveryAddressFullName"
                  value={names.licenceDeliveryAddressFullName?.value}
                />
              ) : null}
              <Text
                label="Delivery address"
                name="licenceDeliveryAddress"
                value={addresses.licenceDeliveryAddress?.value}
              />
            </div>
            }
          </div>
          <div className="photo full-width">
            <img src={images.licenceFront.value} alt="Licence front" />
            <p>Licence front</p>
            <img src={images.licenceBack.value} alt="Licence back" />
            <p>Licence back</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  addresses,
  club,
  deliveryMethod,
  images,
  licenceClass,
  licenceExpiry,
  licenceNumber,
  names,
  stateOfIssue,
  wearGlasses
}) => {
  return {
    addresses,
    club,
    deliveryMethod,
    images,
    licenceClass,
    licenceExpiry,
    licenceNumber,
    names,
    stateOfIssue,
    wearGlasses
  };
};

const VisibleLicenceDetails = connect(mapStateToProps)(LicenceDetails);

export default VisibleLicenceDetails;
