/**
 * AAA IDP date of travel reducer
 * @flow
 */
import { DATE_OF_TRAVEL, DELETE, VALIDATE } from '../../actions/types';
import type { ActionType } from '../../types/Types';
import moment from 'moment';
import business from 'moment-business';
import type Moment from 'moment';

type State = {
  error: boolean,
  errorMessage: string,
  required: boolean,
  valid: boolean | null,
  value: string,
};

export const initialState = {
  error: false,
  errorMessage: '',
  required: true,
  valid: null,
  value: '',
};

export default function(state: State = initialState, action: ActionType) {
  switch (action.type) {
    case DATE_OF_TRAVEL: {
      const { value } = action;
      return validateDate({ ...state, value });
    }

    case DELETE:
      return initialState;

    case VALIDATE:
      return validateDate(state);

    default:
      return state;
  }
}

/**
 * Validate date
 */
export function validateDate(state: State, now: Moment = moment()) {
  const { value } = state;
  const date = moment(value, 'YYYY-MM-DD', true);
  const dateValid = date.isValid();
  const bizDays = moment(now);
  const minDays = 2;
  const delivery = business.addWeekDays(bizDays, minDays);

  switch (true) {
    // If this isn't a valid date, update the error state.
    case !dateValid:
      return {
        ...state,
        error: true,
        errorMessage: '',
        valid: false,
      };

    // Ensure travel date is not in the past
    case date.diff(now, 'days') < 0:
      return {
        ...state,
        error: true,
        errorMessage: 'Your intended travel date cannot be in the past',
        valid: false,
      };

    // Ensure travel date is at least 2 business days in the future
    case date.isBefore(delivery):
      return {
        ...state,
        error: true,
        errorMessage: `If you’re travelling within the next ${minDays} business days, we won’t have time to post your permit to you. It’s best you apply in-person at a retail branch so a permit can be issued immediately. <a href="https://www.aaa.asn.au/idp-branches" target="_blank" nofollow noreferrer>Find closest retail branch.</a>`,
        valid: false,
      };

    // travel date is valid
    default:
      return {
        ...state,
        error: false,
        errorMessage: '',
        valid: true,
      };
  }
}
