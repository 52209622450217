/**
 * AAA IDP postage delivery method
 * @flow
 */
import React from 'react';
import Info from '../info/Info';
import { DeliveryAddress } from '../delivery-address/DeliveryAddress';

export class DeliveryMethodPostage extends React.Component<Props, State> {

  render() {
    const { bugsnagClient } = this.props;

    return (
      <div>
        <Info
          info="Once your application is reviewed and approved, your permit will be dispatched within 3 business days in metropolitan areas. It may take longer for public holidays and regional delivery subject to Australia Post delivery times. You will receive a confirmation email with tracking details." />
        <DeliveryAddress bugsnagClient={bugsnagClient} />
      </div>
    )
  }
}

export default DeliveryMethodPostage;
