/**
 * AAAA IDP Payment next steps component
 * @flow
 */
import * as React from 'react';
import './NextSteps.css';
import { clubDomain, CLUB_CONTACTS } from '../../../data/Data';

type Props = {
  content: Array<Object>,
  postageDescription: null | string
};

class NextSteps extends React.Component<Props> {
  static defaultProps = { content: [] };

  render() {
    const { content, postageDescription } = this.props;
    const { hostname } = window.location;
    const club = clubDomain(hostname);
    const contacts = CLUB_CONTACTS[club];

    return (
      <div className="NextSteps">
        <div className="steps">
          <div className="path path1"></div>
          <div className="path path2"></div>
          {content.map((item, index) => {
            return (
              <div key={index} className="box">
                <div className="head">
                  <div className="index">{index + 1}</div>
                  <h4 className="title">{item.title}</h4>
                </div>
                <div className="content">
                  <div className="vpath-container">
                    <div className="vpath"></div>
                  </div>
                  <div className="copy" dangerouslySetInnerHTML={{ __html: item.content }} />
                  {postageDescription && postageDescription === 'In-store pickup' ? (
                    <div className="copy" dangerouslySetInnerHTML={{ __html: item.contentPickup }} />
                  ) : (
                    <div className="copy" dangerouslySetInnerHTML={{ __html: item.contentPostage }} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="contact">
          For any questions about your order please contact {club}:{' '}
          {contacts.phone ? (
            <span>
              phone <a href={'tel:' + contacts.phone}>{contacts.phone}</a>
            </span>
          ) : (
            ''
          )}{' '}
          {contacts.phone && contacts.email ? 'or' : ''}{' '}
          {contacts.email ? (
            <span>
              email <a href={'mailto:' + contacts.email}>{contacts.email}</a>
            </span>
          ) : (
            ''
          )}
          .
        </div>
      </div>
    );
  }
}

export default NextSteps;
