/**
 * AutoFill fields for local, dev and stage
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  acceptCookies,
  acceptTerms,
  acceptDigitalLicence,
  autoClub,
  dateOfBirth,
  dateOfTravel,
  deliveryMethod,
  deliveryPickUpStore,
  email,
  firstName,
  image,
  imageError,
  imageUploaded,
  phoneCountryCode,
  phoneNumber,
  lastName,
  licenceAddress,
  licenceClass,
  licenceDeliveryAddressLocality,
  licenceDeliveryAddressPostcode,
  licenceDeliveryAddressState,
  licenceDeliveryAddressStreet,
  licenceAddressLocality,
  licenceAddressPostcode,
  licenceAddressState,
  licenceAddressStreet,
  licenceExpiry,
  licenceNumber,
  membershipNumber,
  middleName,
  stateOfIssue,
  title
} from '../../actions';
import moment from 'moment';
import {
  API,
  PHONE_COUNTRY_CODE,
  AUTO_CLUB,
  IS_LOCAL,
  IS_DEV,
  IS_STAGE,
  LICENCE_CLASS,
  OPTIONS,
  STATES,
  TITLE,
  STORES_API
} from '../../data/Data';
import { WORDS, RECENT_PHOTO, LICENCE } from '../../data/Mock';
import type { Application, AppState, ApiResult } from '../../types/Types';
import './AutoFill.css';

type Props = {
  application: Application,
  acceptCookies: (value: boolean) => *,
  acceptTerms: (value: boolean) => *,
  acceptDigitalLicence: (value: boolean) => *,
  autoClub: (value: string) => *,
  bugsnagClient: { notify: Error => * },
  phoneCountryCode: (value: string) => *,
  dateOfBirth: (value: string) => *,
  dateOfTravel: (value: string) => *,
  deliveryMethod: (value: string) => *,
  deliveryPickUpStore: number,
  setDeliveryPickUpStore: (value: number) => *,
  email: (value: string) => *,
  firstName: (value: string) => *,
  image: (name: string, value: string) => *,
  images: AppState,
  imageError: boolean,
  imageLoaded: null | boolean,
  imageUploaded: (name: string, uploaded: boolean) => *,
  phoneNumber: (value: string) => *,
  lastName: (value: string) => *,
  licenceAddress: (value: string) => *,
  licenceAddressLocality: (value: string) => *,
  licenceAddressState: (value: string) => *,
  licenceAddressStreet: (value: string) => *,
  licenceAddressPostcode: (value: string) => *,
  licenceDeliveryAddress: (value: string) => *,
  licenceDeliveryAddressLocality: (value: string) => *,
  licenceDeliveryAddressState: (value: string) => *,
  licenceDeliveryAddressStreet: (value: string) => *,
  licenceDeliveryAddressPostcode: (value: string) => *,
  licenceClass: (value: string) => *,
  licenceExpiry: (value: string) => *,
  licenceNumber: (value: string) => *,
  membershipNumber: (value: string) => *,
  middleName: (value: string) => *,
  stateOfIssue: (value: string) => *,
  title: (value: string) => *
};

type State = {
  show: boolean
};

const AUTO_RE = new RegExp(/autofill/);

class AutoFill extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { hostname, search } = window.location;
    let show = false;

    switch (true) {
      case IS_LOCAL.test(hostname):
      case IS_DEV.test(hostname):
      case IS_STAGE.test(hostname) && AUTO_RE.test(search):
        show = true;
        break;

      default:
      // no op
    }

    this.state = { show };
  }

  /**
   * Return a random word from our Hipster Ipsum word list
   */
  randomWord = (capitalise: boolean = true, arr: Array<string> = WORDS) => {
    const index = Math.floor(Math.random() * arr.length);
    const word = arr[index];
    const value = capitalise ? `${word.substr(0, 1).toUpperCase()}${word.substr(1)}` : word.toLowerCase();
    return value;
  };

  /**
   * Return a random value from a select drop down value array
   */
  randomValue = (arr: Array<{ title: string, value: string }> = PHONE_COUNTRY_CODE) => {
    const index = Math.floor(Math.random() * (arr.length - 1)) + 1;
    const { value } = arr[index];
    return value;
  };

  /**
   * Return a random number of a certain length of digits
   */
  randomNumber = (length: number = 10) => {
    const pow = Math.pow(10, length);
    let rand = Math.random();

    switch (true) {
      case rand < 0.001:
        rand = rand * 1000;
        break;

      case rand < 0.01:
        rand = rand * 100;
        break;

      case rand < 0.1:
        rand = rand * 10;
        break;

      default:
      // no op
    }

    return Math.floor(rand * pow);
  };

  /**
   * Return a random number
   */
  randomDate = (scope: string = 'future') => {
    const date = moment();
    date.month(Math.floor(Math.random() * 11));

    switch (scope) {
      case 'birth': {
        const years = Math.floor(Math.random() * 10) + 19;
        date.subtract(years, 'y').format('YYYY-MM');
        const days = date.daysInMonth();
        date.day(Math.floor(Math.random() * days));
        return date.format('YYYY-MM-DD');
      }

      // future
      default: {
        const years = Math.floor(Math.random() * 4) + 1;
        date.add(years, 'y').format('YYYY-MM-DD');
        const days = date.daysInMonth();
        date.day(Math.floor(Math.random() * days));
        return date.format('YYYY-MM-DD');
      }
    }
  };

  /**
   * Return a random club store
   */
  randomClubStore = () => {
    const { application } = this.props;
    const clubId = application.club.id;

    return fetch(`${STORES_API}/clubs/${clubId}/stores`)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(response => {
        return response.data[Math.floor(Math.random() * response.data.length)];
      })
      .catch(err => console.error(err));
  }

  /**
   * convert base64 to Blob
   */
  imageToBlob = (uri: string, name: string) => {
    const { bugsnagClient } = this.props;
    fetch(uri)
      .then(res => res.blob())
      .then(blob => this.uploadImage(blob, name))
      .catch(err => {
        bugsnagClient.notify(err);
        imageError(name, true, 'Error uploading image. Could not convert to blob');
      });
  };

  /**
   * Upload image
   */
  uploadImage = (file: Blob, name: string) => {
    const { application } = this.props;
    const { submission_token } = application;
    const { id } = application;
    const url = `${API}/${id}/images`;
    const type = name === 'recentPhoto' ? 'passport' : name === 'licenceFront' ? 'licence-front' : 'licence-back';
    const formData = new FormData();

    formData.append('file', file, `${name}.jpg`);
    formData.append('type', type);
    formData.append('submission_token', submission_token);

    // fetch sets the right headers when passed a FormData object as `body:`
    // eslint-disable-next-line no-unused-vars
    let { headers, ...options } = OPTIONS;

    options = {
      ...options,
      body: formData
    };

    fetch(url, options)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(response => this.apiResponse(response, name))
      .catch(err => this.apiError(err, null, name));
  };

  /**
   * API response
   *
   * @param {object} response - the API response
   */
  apiResponse = (response: ApiResult, name: string) => {
    const { imageUploaded } = this.props;

    switch (true) {
      case response['errors'] !== undefined: {
        // Set a default message.
        let message = 'Sorry, there has been an error uploading your photo';

        // If we have received an error title, use that title for the message.
        if (response['message'] !== undefined) {
          message = response.message;
        }

        const error = new Error(message);

        this.apiError(error, response, name);
        return false;
      }

      default:
        imageUploaded(name, true);

        return true;
    }
  };

  /**
   * API error
   */
  apiError = (error: Error, response: ApiResult | null = null, name: string) => {
    const { bugsnagClient } = this.props;
    imageUploaded(name, false);

    if (response) {
      error.message = `${error.message}. ${JSON.stringify(response)}`;
    }

    // Send an error report to BugSnag
    bugsnagClient.notify(error);
  };

  fill = () => {
    // Address (AU only)
    const street = `${this.randomNumber(3)} ${this.randomWord()} St.`;
    const locality = this.randomWord();
    const state = this.randomValue(STATES);
    const postcode = this.randomNumber(4).toFixed(0);
    const address = `${street} ${locality}, ${state}, ${postcode}`;

    // image

    for (let field in this.props) {
      switch (field) {
        case 'acceptCookies':
          this.props[field](true);
          break;

        case 'acceptTerms':
          this.props[field](true);
          break;

        case 'acceptDigitalLicence':
          this.props[field](true);
          break;

        case 'autoClub': {
          const value = this.randomValue(AUTO_CLUB);
          this.props[field](value);
          break;
        }
        case 'phoneCountryCode': {
          const value = this.randomValue(PHONE_COUNTRY_CODE);
          this.props[field](value);
          break;
        }

        case 'dateOfBirth': {
          const value = this.randomDate('birth');
          this.props[field](value);
          break;
        }

        case 'dateOfTravel': {
          const value = this.randomDate();
          this.props[field](value);
          break;
        }

        case 'deliveryMethod': {
          const {setDeliveryPickUpStore} = this.props;
          this.randomClubStore().then(clubStore => {
            if (clubStore && clubStore.id) {
              const value = Math.random() > 0.5 ? 'Postage' : 'PickUpInStore';
              this.props[field](value);
              if (value === 'PickUpInStore') {
                setDeliveryPickUpStore(clubStore.id)
              }
            }
            else {
              this.props[field]('Postage');
            }
          })

          break;
        }

        case 'email': {
          const value = `${this.randomWord(false)}@${this.randomWord(false)}.com`;
          this.props[field](value);
          break;
        }

        case 'firstName': {
          const value = this.randomWord();
          this.props[field](value);
          break;
        }

        case 'image': {
          this.props[field]('recentPhoto', RECENT_PHOTO);
          this.imageToBlob(RECENT_PHOTO, 'recentPhoto');

          this.props[field]('licenceBack', LICENCE);
          this.imageToBlob(LICENCE, 'licenceBack');

          this.props[field]('licenceFront', LICENCE);
          this.imageToBlob(LICENCE, 'licenceFront');
          break;
        }

        case 'phoneNumber': {
          const options = [`9${this.randomNumber(7)}`, `0404${this.randomNumber(6)}`];
          const value = options[Math.floor(Math.random() * options.length)];

          this.props[field](value);
          break;
        }

        case 'lastName': {
          const value = this.randomWord();
          this.props[field](value);
          break;
        }

        case 'licenceClass': {
          const value = this.randomValue(LICENCE_CLASS);
          this.props[field](value);
          break;
        }

        case 'licenceAddress': {
          this.props[field](address);
          break;
        }

        case 'licenceAddressLocality': {
          this.props[field](locality);
          break;
        }

        case 'licenceAddressPostcode': {
          this.props[field](postcode);
          break;
        }

        case 'licenceAddressState': {
          this.props[field](state);
          break;
        }

        case 'licenceAddressStreet': {
          this.props[field](street);
          break;
        }

        case 'licenceDeliveryAddressLocality': {
          this.props[field](locality);
          break;
        }

        case 'licenceDeliveryAddressPostcode': {
          this.props[field](postcode);
          break;
        }

        case 'licenceDeliveryAddressState': {
          this.props[field](state);
          break;
        }

        case 'licenceDeliveryAddressStreet': {
          this.props[field](street);
          break;
        }

        case 'licenceExpiry': {
          const value = this.randomDate();
          this.props[field](value);
          break;
        }

        case 'licenceNumber': {
          const value = `${this.randomNumber(8)}`;
          this.props[field](value);
          break;
        }

        case 'membershipNumber': {
          const value = `${this.randomNumber(8)}`;
          this.props[field](value);
          break;
        }

        case 'middleName': {
          const value = this.randomWord();
          this.props[field](value);
          break;
        }

        case 'stateOfIssue': {
          const value = this.randomValue(STATES);
          this.props[field](value);
          break;
        }

        case 'title': {
          const value = this.randomValue(TITLE);
          this.props[field](value);
          break;
        }

        default:
        // no op
      }
    }
  };

  render() {
    const { show } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className="AutoFill" onClick={this.fill} tabIndex="0" role="button" title="Autofill form for testing">
        <FontAwesomeIcon icon="pencil" className="left" />
        Autofill
      </div>
    );
  }
}

const mapStateToProps = ({ application, images, deliveryPickUpStore }) => {
  return { application, images, deliveryPickUpStore };
};

const mapDispatchToProps = dispatch => {
  return {
    acceptCookies: (value: boolean) => {
      dispatch(acceptCookies(value));
    },

    acceptTerms: (value: boolean) => {
      dispatch(acceptTerms(value));
    },
    acceptDigitalLicence:  (value: boolean) => {
      dispatch(acceptDigitalLicence(value));
    },
    autoClub: (value: string) => {
      dispatch(autoClub(value));
    },
    phoneCountryCode: (value: string) => {
      dispatch(phoneCountryCode(value));
    },
    dateOfBirth: (value: string) => {
      dispatch(dateOfBirth(value));
    },
    dateOfTravel: (value: string) => {
      dispatch(dateOfTravel(value));
    },
    deliveryMethod: (value: string) => {
      dispatch(deliveryMethod(value));
    },
    setDeliveryPickUpStore: (value: string) => {
      dispatch(deliveryPickUpStore(value));
    },
    email: (value: string) => {
      dispatch(email(value));
    },
    firstName: (value: string) => {
      dispatch(firstName(value));
    },
    image: (name: string, value: string) => {
      dispatch(image(name, value));
    },
    imageError: (name: string, error: boolean, message: string) => {
      dispatch(imageError(name, error, message));
    },
    imageUploaded: (name: string, value: boolean) => {
      dispatch(imageUploaded(name, value));
    },
    phoneNumber: (value: string) => {
      dispatch(phoneNumber(value));
    },
    lastName: (value: string) => {
      dispatch(lastName(value));
    },
    licenceAddress: (value: string) => {
      dispatch(licenceAddress(value));
    },
    licenceAddressLocality: (value: string) => {
      dispatch(licenceAddressLocality(value));
    },
    licenceAddressPostcode: (value: string) => {
      dispatch(licenceAddressPostcode(value));
    },
    licenceAddressState: (value: string) => {
      dispatch(licenceAddressState(value));
    },
    licenceAddressStreet: (value: string) => {
      dispatch(licenceAddressStreet(value));
    },
    licenceDeliveryAddressLocality: (value: string) => {
      dispatch(licenceDeliveryAddressLocality(value));
    },
    licenceDeliveryAddressPostcode: (value: string) => {
      dispatch(licenceDeliveryAddressPostcode(value));
    },
    licenceDeliveryAddressState: (value: string) => {
      dispatch(licenceDeliveryAddressState(value));
    },
    licenceDeliveryAddressStreet: (value: string) => {
      dispatch(licenceDeliveryAddressStreet(value));
    },
    licenceClass: (value: string) => {
      dispatch(licenceClass(value));
    },
    licenceExpiry: (value: string) => {
      dispatch(licenceExpiry(value));
    },
    licenceNumber: (value: string) => {
      dispatch(licenceNumber(value));
    },
    membershipNumber: (value: string) => {
      dispatch(membershipNumber(value));
    },
    middleName: (value: string) => {
      dispatch(middleName(value));
    },
    stateOfIssue: (value: string) => {
      dispatch(stateOfIssue(value));
    },
    title: (value: string) => {
      dispatch(title(value));
    }
  };
};

const VisibleAutoFill = connect(mapStateToProps, mapDispatchToProps)(AutoFill);

export default VisibleAutoFill;
