/**
 * AAA IDP pick up in-store delivery method
 * @flow
 */
import React from 'react';
import type { Application, AppState } from '../../types/Types';
import { connect } from 'react-redux';
import ClubStore from '../club-store/ClubStore';
import './DeliveryMethodPickUpInStore.css';
import ErrorMessage from '../error-message/ErrorMessage';
import ClubStoresMap from '../club-stores-map/ClubStoresMap';

type Props = {
  ...AppState,
  application: Application,
  clubStores: Array,
  deliveryPickUpStore: number,
};

export class DeliveryMethodPickUpInStore extends React.Component<Props, State> {

  render() {
    const { clubStores, deliveryPickUpStore } = this.props;
    let errorMessage = deliveryPickUpStore.error ? 'A store must be selected.' : '';
    if (clubStores && clubStores.length === 1) {
      deliveryPickUpStore.value = clubStores[0].id;
    }
    return (
      <div className="form-element-container DeliveryMethodPickUpInStore">
        <div className="label title">Select a store:</div>
        <div className="club-store-selector">
          <div className="radio-buttons">
            {(clubStores || []).map((clubStore) => (
              <ClubStore key={clubStore.id} clubStore={clubStore} />
            ))}
          </div>
          <ClubStoresMap clubStores={clubStores} />
          <ErrorMessage message={errorMessage} error={deliveryPickUpStore.error} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ application, deliveryPickUpStore }) => {
  return { application, deliveryPickUpStore };
};

const VisibleDeliveryMethodPickUpInStore = connect(mapStateToProps)(DeliveryMethodPickUpInStore);

export default VisibleDeliveryMethodPickUpInStore;
