/**
 * AAA IDP store map
 * @flow
 */

import React from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';

type Props = {
  clubStores: Array,
};

export class ClubStoresMap extends React.Component<Props> {
  componentDidMount() {
    const { clubStores } = this.props;
    const markerIcon = L.icon({
      iconUrl: markerIconPng,
    });

    let map = L.map('map').setView([-33.8478053,150.6023375], 13);

    let coordinates = [];
    (clubStores || []).forEach(clubStore => {
      if (clubStore.address_latitude && clubStore.address_longitude) {
        coordinates.push([clubStore.address_latitude, clubStore.address_longitude]);
        L.marker([clubStore.address_latitude, clubStore.address_longitude], { icon: markerIcon }).addTo(map)
          .bindPopup('<b>' + clubStore.name + '</b><br />' + clubStore.address_1 + '<br />' + clubStore.address_suburb + ', ' + clubStore.address_state);
      }
    });

    if (coordinates.length > 0) {
      const bounds = new L.LatLngBounds(coordinates);
      map.fitBounds(bounds);
    }

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
  }

  render() {
    return (
      <div className="club-stores-map" id="map"></div>
    )
  }
}

export default ClubStoresMap;
