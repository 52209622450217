/**
 * AAA IDP Footer component
 * @flow
 */
import * as React from 'react';
import ErrorApi from '../../../components/error-api/ErrorApi';
import Modal from '../../../components/modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteAll } from '../../../actions/';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Fn } from '../../../functions/Functions';
import { API, OPTIONS, POSTAGE } from '../../../data/Data';
import type { Application, AppState, ApiResult, Transaction } from '../../../types/Types';
import type { Bugsnag } from '@bugsnag/js';

type Props = {
  ...AppState,
  application: Application,
  bugsnagClient: Bugsnag,
  canPay: boolean,
  cookies: boolean,
  terms: { value: boolean },
  digitalLicenceTerms: { value: boolean },
  deleteAll: () => *,
  jest: boolean,
  location: {
    search: string
  },
  transaction: Transaction,
  validate: () => *
};

type State = {
  apiError: boolean,
  apiErrorMessage: string,
  focus: boolean,
  modal: boolean,
  next: boolean,
  spinner: boolean,
  submitting: boolean
};

export class Footer extends React.Component<Props, State> {
  static defaultProps = {
    application: {
      club: {
        abbreviation: 'RACV'
      },
      referrer: 'http://localhost:3000/'
    },
    canPay: false,
    club: {
      autoClub: {
        error: false
      }
    },
    contacts: {
      contactNumber: {
        error: false
      }
    },
    dateOfBirth: {
      error: false
    },
    dateOfTravel: {
      error: false
    },
    deliveryMethod: {
      error: false
    },
    deliveryPickUpStore: {
      error: false
    },
    email: {
      error: false
    },
    jest: false,
    licenceClass: {
      error: false
    },
    licenceExpiry: {
      error: false
    },
    licenceNumber: {
      error: false
    },
    location: {
      search: ''
    },
    names: {
      fullName: {
        error: false
      }
    },
    placeOfBirth: {
      error: false
    },
    postage: {
      ...POSTAGE[0]
    },
    stateOfIssue: {
      error: false
    },
    wearGlasses: {
      error: false
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      apiError: false,
      apiErrorMessage: '',
      focus: false,
      modal: false,
      next: false,
      spinner: false,
      submitting: false
    };
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    // $FlowFixMe
    const { key } = e;
    const { focus } = this.state;

    if (focus && (key === ' ' || key === 'Enter')) {
      this.submit();
    }
  };

  /**
   * onFocus
   */
  onFocus = () => {
    this.setState({
      focus: true
    });
  };

  /**
   * onBlur
   */
  onBlur = () => {
    this.setState({
      focus: false
    });
  };

  /**
   * submit
   */
  submit = () => {
    const { canPay, application } = this.props;

    if (!canPay) {
      return;
    }

    const data = Fn.formatData(this.props);
    const options = {
      ...OPTIONS,
      method: 'PUT',
      body: JSON.stringify(data)
    };
    const url = `${API}/${application.id}`;

    this.setState({
      submitting: true,
      spinner: true
    });

    fetch(url, options)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(result => this.success(result))
      .catch(err => this.error(err));
  };

  /**
   * success posting to API
   */
  success = (result: ApiResult) => {
    const { jest } = this.props;

    if (result?.data?.order_status && result?.data?.order_status !== 'Pending') {
       this.cancelAndRedirect();
    }


    // handle API validation errors
    if (result.errors || result.message === 'Server Error') {
      this.validationError(result);
    } else {
      this.setState({
        modal: true
      });

      // scroll to top
      if (!jest) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  };



  /**
   * Go to Payment page
   */
  next = (e: SyntheticEvent<>) => {
    e.preventDefault();

    this.setState({
      next: true
    });
  };

  /**
   * API validation error(s)
   */
  validationError = (result: ApiResult) => {
    this.setState({
      apiError: true,
      apiErrorMessage: result.message,
      spinner: false
    });

    const msg = `API error${result.message ? ` ${result.message}` : ''}${
      result.errors ? ` Error(s): ${JSON.stringify(result.errors)}` : ''
    }`;
    const error = new Error(msg);
    this.error(error);
  };

  /**
   * Error
   */
  error = (err: Error) => {
    const { application, bugsnagClient, jest } = this.props;

    if (jest) return null;
    console.warn(err);

    err.message = `${err.message}; Application id: ${application.id}`;

    bugsnagClient.notify(err);
  };

  /**
   * Cancel application and redirect
   */
  cancelAndRedirect = () => {
    const { application } = this.props;
    deleteAll();
    window.location = application.referrer;
  };

  render() {
    const { application, canPay } = this.props;
    const { apiError, modal, next, spinner } = this.state;

    switch (true) {
      case next:
        return <Redirect to="/payment" />;

      default:
        return (
          <React.Fragment>
            <ErrorApi apiError={apiError} page="payment" />
            <Modal name={application.club.abbreviation} next={this.next} show={modal} />
            <section className="footer">
              <Link className="button reverse previous" to="/details">
                <FontAwesomeIcon icon="chevron-left" />
                Previous: Details
              </Link>
              <div
                className={`button next${apiError || !canPay ? ' disabled' : ''}`}
                onClick={() => this.submit()}
                tabIndex="0"
                role="button"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
              >
                {spinner ? <FontAwesomeIcon icon="spinner" spin={true} /> : <FontAwesomeIcon icon="chevron-right" />}
                Next: Payment
              </div>
            </section>
          </React.Fragment>
        );
    }
  }
}

const mapStateToProps = ({
  addresses,
  application,
  club,
  cookies,
  deliveryMethod,
  deliveryPickUpStore,
  digitalLicenceTerms,
  images,
  licenceClass,
  licenceExpiry,
  licenceNumber,
  contacts,
  dateOfBirth,
  dateOfTravel,
  email,
  names,
  placeOfBirth,
  postage,
  stateOfIssue,
  transaction,
  wearGlasses,
  terms
}) => {
  return {
    addresses,
    application,
    club,
    cookies,
    deliveryMethod,
    deliveryPickUpStore,
    images,
    licenceClass,
    licenceExpiry,
    licenceNumber,
    contacts,
    dateOfBirth,
    dateOfTravel,
    digitalLicenceTerms,
    email,
    names,
    placeOfBirth,
    postage,
    stateOfIssue,
    transaction,
    wearGlasses,
    terms
  };
};


const mapDispatchToProps = dispatch => {
  return {
    deleteAll: () => {
      dispatch(deleteAll());
    }
  };
};

const VisibleFooter = connect(mapStateToProps, mapDispatchToProps)(Footer);

export default VisibleFooter;
